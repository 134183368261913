module.exports = [{
      plugin: require('C:/Users/Mati/Desktop/jurczynski/lawyer-01/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.jurczynskikancelaria.pl/"},
    },{
      plugin: require('C:/Users/Mati/Desktop/jurczynski/lawyer-01/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-144469339-1"},
    },{
      plugin: require('C:/Users/Mati/Desktop/jurczynski/lawyer-01/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
